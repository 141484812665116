import React, { useEffect, useState, useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import _ from "lodash";
import ReactSelect, { components } from "react-select";
import { useFormikContext } from "formik";
import { FieldError, useFieldCSSClasses } from "./FieldError";
import useHideAndShowField from "./hooks/useHideAndShowField";
import { getAttr } from "src/helpers";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div className={`d-flex ${!props.data.icon && "justify-content-between"} align-items-center`}>
        {props.data.icon && <div className="mr-2">{props.data.icon}</div>}
        <div>{props.data.label}</div>
        <div style={{ fontSize: 12 }}>{props.data.subLabel}</div>
      </div>
    </components.Option>
  );
};

const Select = ({
  label,
  size = 12,
  placeholder,
  isLoading,
  withSubmit,
  onInputChange,
  options = [],
  multiple = false,
  chainedOptions,
  refreshOptions = true,
  loadOptions,
  loadDebounceOptions,
  loadDebounceOptions1,
  loadValueAttr,
  initialValue = "",
  saveOptions,
  required = false,
  disabled = false,
  relyOn = null,
  chainedField = null,
  chainedFieldValue,
  field,
  saveLabel = false,
  form,
  inputGroupClassName = "form-group",
  fixInitialValue=undefined,
  formRepeater=false,
  getFirstValue= false,
  emit,
  initialValueChainedField="",
  resetConditionValue=undefined,
  onSubmit,
  ...props
}) => {
  const fieldCSSClasses = useFieldCSSClasses(
    form.touched,
    form.errors,
    field.name
  );
  const formik = useFormikContext();
  const conditionalShowAndHideClassName = useHideAndShowField({
    formik,
    fieldName: field.name,
    ...props,
  });
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading,setLoading] = useState(false);

  const onSelectChange = (option,{action}) => {
    let choices = "";
    let labels = "";
    if (action === "clear" && !_.isEmpty(chainedField)){
      form.setFieldValue(chainedField, []);
        formik.setFieldValue(chainedField, []);
        if (_.isFunction(emit)){
          emit({type:`CLEAR_${field.name.toUpperCase()}`}) // for clearing
        }

    }
    if (!_.isArray(option) && _.has(option, "value")) {
      choices = option.value;
      if (!_.isEmpty(chainedField)) {
        form.setFieldValue(chainedField, []);
        formik.setFieldValue(chainedField, []);
      }
      if (saveLabel && _.has(option, "label")) {
        labels = option.label;
      }
    } else if (_.isArray(option)) {
      choices = option.map((choice) => choice.value);
      labels = option.map((label) => label.label);
    }
    form.setFieldTouched(field.name, true);
    form.setFieldValue(field.name, choices);
    if (saveLabel) {
      form.setFieldValue(`${field.name}ConfirmationLabel`, labels);
    }
    if (_.isFunction(emit)){
      emit({type:`CHANGE_${field.name.toUpperCase()}`,payload:choices})
    }
    if (_.isFunction(onSubmit)){
      let values = {};
      values[`${field.name}`] = option.value
      onSubmit(values)
    }
  };

  const refresh = (touched = false) => {
    if (touched) formik.setFieldTouched(field.name, true);
    formik.setFieldValue(field.name, field.value ? field.value : []);
  };



  useEffect(() => {
    _.isFunction(withSubmit) &&
      !_.isNull(form.values) &&
      withSubmit(form?.values);
    return () => {};
  }, [form.values, withSubmit]);
  useEffect(() => {
    let choices = [];
    if (!_.isArray(field.value) && field.value && _.isArray(selectedOptions)) {
      choices.push(
        selectedOptions.find((option) => option.value === field.value)
      );
    }
    if (_.isArray(field.value) && _.isArray(selectedOptions)) {
      choices = selectedOptions.filter((option) =>
        field.value.includes(option.value)
      );
    }

    const fieldTouched =
      _.get(form.touched, field.name, false) || _.isEmpty(selectedOptions);

    if (!_.isEmpty(saveOptions) && _.isPlainObject(saveOptions)) {
      form.setFieldValue(
        saveOptions.ref,
        _.flatten(_.map(choices, saveOptions.attr))
      );
    }
    if (!_.isEmpty(saveOptions) && fieldTouched && _.isArray(saveOptions)) {
      saveOptions.forEach((field) => {
        let value = _.map(choices, field.attr);
        //if (!_.every(value, _.isEmpty)){
        value = _.flatten(value);
        form.setFieldValue(
          field.ref,
          _.isFunction(field.formatter) ? field.formatter(value) : value
        );
        //}
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value && selectedOptions]);

  useEffect(() => {
    if (_.isPlainObject(field.value) && _.has(field.value, "id")) {
      form.setFieldValue(field.name, _.get(field.value, "id", ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_.isPlainObject(field.value)]);

  useEffect(() => {
    const loadValues = _.get(formik.values, loadValueAttr);
    if (
      _.isArray(loadValues) &&
      loadValues.every((item) => _.has(item, "id"))
    ) {
      form.setFieldValue(
        field.name,
        loadValues.map((item) => _.get(item, "id", ""))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_.get(formik.values, loadValueAttr)]);

  const memoizedLoadOptions = useMemo(() => {
    return async () => {
      await loadOptions(setSelectedOptions);
      return setSelectedOptions;
    };
  }, []);

  useEffect(() => {
    if (_.isFunction(loadOptions) && _.isEmpty(selectedOptions)) {
      memoizedLoadOptions();
    }
    if (options && _.isEmpty(selectedOptions)) {
      setSelectedOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  useEffect(() => {
    if (_.isFunction(loadOptions) && !_.isNull(relyOn) && fixInitialValue) {
      formik.setFieldValue(field.name,fixInitialValue)
      loadOptions(setSelectedOptions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAttr(formik.values,relyOn)]);

   useEffect(() => {

    if (saveLabel) {
      form.setFieldValue(`${field.name}ConfirmationLabel`, selectedOptions.find(item=>item.value === getAttr(formik.values,field.name))?.label);
    }
     return () => {

     }
   }, [selectedOptions])




  useEffect(() => {

     if(!_.isEmpty(initialValueChainedField) && resetConditionValue){

       if  (getAttr(formik.values,initialValueChainedField,undefined) === resetConditionValue) {
         form.setFieldValue(field.name, "");
         formik.setFieldValue(field.name, "")


       }
       else{
         form.setFieldValue(field.name, initialValue);
         formik.setFieldValue(field.name, initialValue);
       }
     }

     return () => {

     }
   }, [getAttr(formik.values,initialValueChainedField)])




   useEffect(() => {

    if (getFirstValue &&  !_.isEmpty(selectedOptions))  {
      form.setFieldValue(`${field.name}`, selectedOptions[0].value);
      formik.setFieldValue(`${field.name}`, selectedOptions[0].value)
    }
     return () => {

     }
   }, [selectedOptions])

  useEffect(() => {
    if (_.isFunction(chainedOptions) && !_.isNull(relyOn) && !formRepeater) {
      chainedOptions(setSelectedOptions, formik.values);
    }
    if (
      !_.isUndefined(chainedOptions) &&
      _.isEmpty(getAttr(formik.values, relyOn)) &&
      refreshOptions === true
    ) {
      // setSelectedOptions([])
      refresh();
    }
    // eslint-disable-next-line
  }, [getAttr(formik.values, relyOn)]);




  useEffect(() => {
    if (_.isFunction(chainedOptions) && !_.isNull(relyOn) && formRepeater) {
      const [formRepeaterName,index] = field.name.split('.');
      const fieldName = formRepeaterName+"["+index+"]."+relyOn
      chainedOptions(setSelectedOptions, getAttr(formik.values,fieldName)); // getAttr dose   all the magic
    }
    if (
      !_.isUndefined(chainedOptions) &&
      _.isEmpty(getAttr(formik.values, relyOn)) &&
      refreshOptions === true
    ) {
      // setSelectedOptions([])
      refresh();
    }
    // eslint-disable-next-line
  }, [formik.values,formRepeater]);


  const selectedValues = useMemo(() => {
    if (!_.isArray(field.value) && _.isArray(selectedOptions)) {
      return selectedOptions.find((option) => option.value === field.value);
    }
    if (_.isArray(field.value) && _.isArray(selectedOptions)) {
      return selectedOptions.filter((option) =>
        field.value.includes(option.value)
      );
    }
    return field.value || (multiple ? [] : "");
    // eslint-disable-next-line
  }, [field.value, selectedOptions]);
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: disabled ? "#D6D6D6" : "#FAFAFA",
      border: "1px solid #D6D6D6",
      minHeight: 40,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#757575", // Custom colour
    }),
    loadingIndicator: (base) => ({
      ...base,
      color: "#E02D39",
    }),
  };

  const [formRepeaterName,index] = field.name.split('.');
  const fieldName = formRepeaterName+"["+index+"]."+relyOn
  const debouncedLoadOptions = useCallback(
      _.debounce((callback,inputValue) => {
        callback(setSelectedOptions,getAttr(formik.values,fieldName),inputValue,setLoading)
      }, 500),
      [getAttr(formik.values,fieldName)]
  );


  const fieldName1 = relyOn;
  const debouncedLoadOptions1 = useCallback(
      _.debounce((callback, inputValue) => {
        callback(setSelectedOptions, getAttr(formik.values, fieldName1), inputValue, setLoading);
      }, 500),
      [getAttr(formik.values, fieldName1)]
  );



  const handleInputChange = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      debouncedLoadOptions(loadDebounceOptions,inputValue);
    }
  };
  const handleInputChange1 = (inputValue, actionMeta) => {
    if (actionMeta.action === 'input-change') {
      debouncedLoadOptions1(loadDebounceOptions1,inputValue);
    }
  };
  return (
    <div
      className={
        inputGroupClassName +
        conditionalShowAndHideClassName +
        ` col-lg-${size}`
      }
    >
      {label && (
        <label className="custom-label-style">
          {" "}
          {label} <span>{required && "*"}</span>
        </label>
      )}
      <ReactSelect
        menuShouldScrollIntoView={false}
        isDisabled={disabled}
        styles={customStyles}
        components={{ IndicatorSeparator: () => null, Option }}
        className={fieldCSSClasses}
        placeholder={placeholder || <FormattedMessage id="GENERAL.SELECT" />}
        options={selectedOptions}
        name={field.name}
        value={selectedValues || []}
        onChange={onSelectChange}
        onBlur={field.onBlur}
        onInputChange={loadDebounceOptions && handleInputChange || loadDebounceOptions1 && handleInputChange1}
        isMulti={multiple}
        noOptionsMessage={() => (
          <FormattedMessage id="GENERAL.SELECT.NO_OPTIONS" />
        )}
        isSearchable
        isClearable
        loadingMessage={() => <FormattedMessage id="GENERAL.LOADING" />}
        isLoading={
          (isLoading && _.isEmpty(options) && _.isEmpty(selectedOptions)) || loading
            ? true
            : false
        }
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary50: "rgb(210 25 25 / 40%)",
            primary25: "rgb(210 25 25 / 40%)",
            primary: "rgb(210 25 25 / 80%)",
          },
        })}
      />
      <FieldError fieldName={field.name} />
    </div>
  );
};

export default Select;
