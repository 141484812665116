import { lazy } from "react"

const AdminLayout = lazy(() =>  import("./modules/admin/layout/Layout"))
const AuthLayout = lazy(() =>  import("./modules/authentication/layout/Layout"))
const Logout = lazy(() =>  import("./modules/authentication/containers/Logout"))
const SSOLayout = lazy(() =>  import("./modules/admin/layout/SSOLayout"))


export default {
  admin: {
    path: "/admin",
    component: AdminLayout
  },
  auth: {
    path: "/auth",
    component: AuthLayout
  },
  ssoLayout:{
    path: "/sso",
    component: SSOLayout
  },

  logout: {
    path: "/logout",
    component: Logout
  }
}
