import { lazy } from "react"
import {combinePathRoutes} from "src/helpers"
import routes from "src/routes"

const AccountInformation = lazy(() => import("./../AccountInformation"))
const UpdateAccount = lazy(() => import("./../UpdateProfile"))

const profile = {
  path: "/profile",
  component: AccountInformation,
}

const updateProfile = {
  path: "/update-profile",
  component: UpdateAccount,
}


const path = routes.ssoLayout.path

export default combinePathRoutes({ path }, {
  profile,
  updateProfile
})
