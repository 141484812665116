import { lazy } from "react"
import { combinePathRoutes } from "src/helpers"
// import ResetPhoneNumber from "src/modules/authentication/containers/ResetPhoneNumber";
import routes from "src/routes"
 
const SSOHome = lazy(() => import("./../SSOHome"))
const ResetPassword = lazy(() => import("../../../../authentication/containers/ResetPassword"));
const ResetPhoneNumber = lazy(() => import("../../../../authentication/containers/ResetPhoneNumber"))
const ProfiledDisplay = lazy(() => import("../components/ProfileDisplay"))


const SSOhome = {
  path: "/home",
  component: SSOHome,
  exact: true,
}
const resetPassword = {
  path: "/reset-password",
  component: ResetPassword,
  hideforCnom: true
}
const resetPhone = {
  path: "/reset-phone",
  component: ResetPhoneNumber,
  hideforCnom: true
}

const profileDisplay = {
  path: "/profile/display",
  component: ProfiledDisplay
}
const path = routes.ssoLayout.path
export default combinePathRoutes({ path }, {
  resetPassword,
  resetPhone,
  profileDisplay,
  SSOhome,
})
