import axios from 'axios'
import { API_BACK_OFFICE_URL, API_BASE_URL, API_EXTERNAL_URL } from '../constants'


export const ping = ({ url = API_BASE_URL, timeout=5000, isOnlineCallback, isOfflineCallback }) => {
  return new Promise(resolve => {
    const isOnline = () => {
      isOnlineCallback()
      resolve(true);
    }
    const isOffline = () => {
      isOfflineCallback()
      resolve(false);
    }

    const xhr = new XMLHttpRequest();

    xhr.onerror = isOnline;
    xhr.ontimeout = isOffline;
    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.HEADERS_RECEIVED) {
        if (xhr.status) {
          isOnline();
        } else {
          isOffline();
        }
      }
    };

    xhr.open("GET", url);
    xhr.timeout = timeout;
    xhr.send();
  });
}


const client = axios.create({
  baseURL: API_BASE_URL,
  // withCredentials:true,
  headers: {
    'Content-Type': 'application/json'
  }
})

// const backCLients = axios.create({
//   baseURL: API_BACK_BASE_URL,
//   headers: {
//     'Content-Type': 'application/json'
//   }
// })


export const makeCall = (method, url, data = {}, headers = {}, params = {}, extra = {}) => {
  return client.request({
    method,
    data,
    url,
    headers,
    params,
    ...extra
  })
}

// export const makeBackCall = (method, url, data = {}, headers = {}, params = {}, extra = {}) => {
//   return backCLients.request({
//     method,
//     data,
//     url,
//     headers,
//     params,
//     ...extra
//   })
// }


const externalClient = axios.create({
  baseURL: API_EXTERNAL_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})


export const makeExternalCall = (method, url, data = {}, headers = {}, params = {}, extra = {}) => {
  return externalClient.request({
    method,
    data,
    url,
    headers,
    params,
    ...extra
  })
}

const backOffice = axios.create({
  baseURL: API_BACK_OFFICE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})
export const makeDynamicCall = (method, url, data = {}, headers = {}, params = {}, extra = {}, baseURL) => {
  if (!baseURL) {
    throw new Error('baseURL must be provided for makeDynamicCall');
  }

  const dynamicClient = axios.create({
    baseURL, // Utilise le baseURL passé dynamiquement
    headers: {
      'Content-Type': 'application/json',
      ...headers, // Ajoute les headers personnalisés
    },
  });

  return dynamicClient.request({
    method,
    data,
    url,
    params,
    ...extra,
  });
};
