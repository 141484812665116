import React from "react"
import {CircularProgress} from "@material-ui/core"
import {toAbsoluteUrl} from "../../../../helpers"

const SplashScreen = () => {
  return (
    <div id="splash-screen">
      <img src="/media/logos/image.png" width="500" alt="Metronic logo" />
      <svg className="splash-spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          stroke-width="5"
        ></circle>
      </svg>
    </div>
  )
}


export default SplashScreen
